import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ToastContainer } from "react-toastify";
import "./App.scss";
import AuthProvider from "./providers/Authentication";
import PermissionProvider from "./providers/Permission";
import { AppRoutes } from "./routes";
import { NavigationHelper } from "./providers/NavigationHelper";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      notifyOnChangeProps: "tracked"
    }
  }
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <PermissionProvider>
          <AppRoutes />
          <ToastContainer />
          <ReactQueryDevtools initialIsOpen={false} />
        </PermissionProvider>
        <NavigationHelper />
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
