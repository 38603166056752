// navigateHelper.ts
import { useNavigate, NavigateFunction } from 'react-router-dom';
import { useEffect, useRef } from 'react';

let navigate: NavigateFunction;

export const NavigationHelper = () => {
  const navigateHook = useNavigate();
  const navigateRef = useRef<NavigateFunction>();

  useEffect(() => {
    navigateRef.current = navigateHook;
  }, [navigateHook]);

  navigate = navigateHook;

  return null;
};

export const getNavigate = () => navigate;
