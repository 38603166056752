import React, { lazy, Suspense } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { AuthContext } from "./providers/Authentication";

const Login = lazy(() => import("./pages/Login"));
const Home = lazy(() => import("./pages/Home"));
const RequestResetPassword = lazy(() => import("./pages/RequestResetPassword"));
const ResetPasswordForm = lazy(() => import("./pages/ResetPasswordForm"));
const Message = lazy(() => import("./pages/Message"));

export function AppRoutes() {
  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/message" element={<Message />} />
        <Route
          path="/password/reset/request"
          element={<RequestResetPassword />}
        />
        <Route path="/password/reset" element={<ResetPasswordForm />} />
        <Route
          path="*"
          element={
            <RequireAuth>
              <Home />
            </RequireAuth>
          }
        />
      </Routes>
    </Suspense>
  );
}

function useAuth() {
  return React.useContext(AuthContext);
}

function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = useAuth();
  let location = useLocation();

  if ((!auth || !auth.getToken()) && location.pathname !== "/login") {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}
