import { Authorizer } from "casbin.js";
import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { ApiService } from "../services";

interface PermissionContextType {
  isAllowed: (action: string, resource: string) => Promise<boolean>;
  fetchPolicies: () => void;
}

interface CasbinPermissions {
  [key: string]: string[];
}

interface PermissionProviderProps {
  children: ReactNode;
}

const PermissionContext = createContext<PermissionContextType | undefined>(
  undefined
);

// Function to reshape the permissions array into the desired format
const reshapeArray = (input: string[][]): CasbinPermissions => {
  const result: CasbinPermissions = {};

  input.forEach(([role, resource, action]) => {
    if (!result[action]) {
      result[action] = [];
    }
    if (!result[action].includes(resource)) {
      result[action].push(resource);
    }
  });

  return result;
};

// Function to fetch policies from the API and reshape them
const fetchPolicies = async (
  get: (url: string) => Promise<any>
): Promise<CasbinPermissions> => {
  const policies = await get("authorization/policy");
  console.log("Fetching policies from API", policies);
  return reshapeArray(policies);
};

// Custom hook to manage permissions
const usePermissionProvider = () => {
  const [authorizer] = useState(() => new Authorizer("manual"));
  const [permissionsLoaded, setPermissionsLoaded] = useState(false);
  const [allActions, setAllActions] = useState(false);

  const getPolicies = useCallback(async () => {
    try {
      const apiService = new ApiService();
      const permissions = await fetchPolicies(apiService.get);
      if ("*" in permissions) setAllActions(true);
      authorizer.setPermission(permissions);
      setPermissionsLoaded(true);
      console.log("Permissions loaded and set in authorizer:", permissions);
    } catch (error) {
      console.error("Failed to fetch policies:", error);
    }
  }, [authorizer]);

  useEffect(() => {
    getPolicies();
  }, [getPolicies]); // Ensure getPolicies is the only dependency here so it's run once

  const isAllowed = useCallback(
    async (action: string = "view", resource: string): Promise<boolean> => {
      if (!permissionsLoaded) {
        console.log("Permissions not loaded yet");
        return false;
      }

      const authAction = allActions ? "*" : action;
      const allowed = await authorizer.canAny(authAction, [resource, "*"]);
      console.log(
        `Checking permission for action: ${authAction}, resource: ${[
          resource,
          "*"
        ]}: ${allowed}`
      );
      return allowed;
    },
    [permissionsLoaded, authorizer, allActions]
  );

  return { isAllowed, getPolicies };
};

// PermissionProvider component to provide the context value
export const PermissionProvider: React.FC<PermissionProviderProps> = ({
  children
}) => {
  const value = usePermissionProvider();

  // Memoize the context value to prevent unnecessary re-renders
  const contextValue = useMemo(
    () => ({ isAllowed: value.isAllowed, fetchPolicies: value.getPolicies }),
    [value.isAllowed, value.getPolicies]
  );

  return (
    <PermissionContext.Provider value={contextValue}>
      {children}
    </PermissionContext.Provider>
  );
};

// Custom hook to use the permissions context
export const usePermissions = (): PermissionContextType => {
  const context = useContext(PermissionContext);
  if (!context) {
    throw new Error("usePermissions must be used within a PermissionProvider");
  }
  return context;
};

export default PermissionProvider;
